import {
  Button,
  Fade,
  Flex,
  Heading,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IoMailOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input } from 'src/components/Form/Input';
import { apiInstance } from 'src/services/api';
import { rememberPassword } from 'src/utilities/getEmailHtml';
import { sendBase64ForEmails } from 'src/utilities/sendBase64ForEmails';
import * as yup from 'yup';
import { createdHashRememberPassword } from '../Company-settings/Users/utils/createdPasswordDefault';

interface props {
  email: string;
}

const schema = yup.object().shape({
  newPassword: yup.string().min(6, 'No mínimo 6 caracteres'),
  repeateNewPassword: yup
    .string()
    .oneOf([null, yup.ref('newPassword')], 'As senhas precisão ser iguais'),
});

export const Recovery = () => {
  const [searchParams] = useSearchParams();

  const hasHash = searchParams.get('hash' || undefined);
  const hasRedirect = searchParams.get('redirect_url' || undefined);

  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const {
    register: registerp,
    handleSubmit: handleSubmitp,
    formState: { isSubmitting: isSubmittingp, errors: errorsp },
  } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleAccountRecovery: SubmitHandler<props> = async (values) => {
    const user = {
      email: values.email,
    };
    try {
      await apiInstance(8006).post('User/rememberPassword', user, {
        headers: {
          Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
        },
      });

      const environmentIsDevelopment =
        window.location.origin.includes('localhost') ||
        window.location.origin.includes('alpha') ||
        window.location.origin.includes('192.168.0.221')
          ? 'https://www.alpha-workspace.londrisoftservices.com.br/'
          : 'https://workspace.londrisoft.com.br';

      const hashRemenberPassword = await createdHashRememberPassword(
        user.email
      );
      const link = `${window.location.origin}/recovery?hash=${hashRemenberPassword}`;

      sendBase64ForEmails({
        emails: [user.email],
        htmlFile: rememberPassword(user.email, link),
      });

      toast({
        title: 'Enviamos um e-mail com as instruções!',
        description:
          'Acesse o link enviado para o e-mail informado. Pode levar até 5 minutos para a mensagem chegar, aguarde',
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      navigate('/');
    } catch (error: any) {
      if (error.response.data === 'Not Found.') {
        toast({
          title: 'E-mail não localizado.',
          description:
            'O E-mail informado não foi encontrado em nossa base de dados.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }
      console.log(error.response);
    }
  };

  const handleNewPassword = async (values: any) => {
    try {
      const user = {
        email: '',
        password: values.newPassword,
        tokenRememberPassword: hasHash,
      };
      await apiInstance(8006).put('User/changerPassword', user, {
        // baseURL: 'http://192.168.0.191:7001',
        headers: {
          Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
        },
      });
      toast({
        title: 'Nova senha cadastrada com sucesso!',
        // description:
        //   'Acesse o link enviado para o e-mail informado. Pode levar até 5 minutos para a mensagem chegar, aguarde',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });

      if (hasRedirect) {
        window.open(`https://${hasRedirect}`, '_self');
        return;
      }

      navigate('/');
    } catch (error: any) {
      toast({
        title: 'Erro ao cadastrar nova senha.',
        description: 'Tente novamente mais tarde.',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
      console.log(error.response);
    }
  };

  if (hasHash) {
    return (
      <Fade in={true} delay={0.2}>
        <Helmet>
          <title>Recovery password | Workspace</title>
        </Helmet>
        <Flex
          height="100vh"
          alignItems="center"
          justifyContent="center"
          backgroundColor={{ base: 'high.pure', md: 'transparent' }}
        >
          <Flex
            as="form"
            onSubmit={handleSubmitp(handleNewPassword)}
            flexDirection="column"
            width={570}
            minHeight={{ base: '100%', md: '420px' }}
            justifyContent="space-around"
            borderWidth={{ base: '0px', md: '1px' }}
            borderColor="low.light"
            py="16px"
            px={{ base: '24px', md: '98px' }}
            boxShadow={{ base: 'none', md: 'sm' }}
            backgroundColor="high.pure"
          >
            <Heading
              color="primary.pure"
              fontSize="24px"
              marginBottom="30px"
              textAlign="center"
            >
              Workspace Londrisoft
            </Heading>
            <Input
              {...registerp('newPassword')}
              label="Nova Senha *"
              type="password"
              error={errorsp.newPassword}
              placeholder="**********"
            />
            <Input
              {...registerp('repeateNewPassword')}
              label="Repita a Senha *"
              type="password"
              error={errorsp.repeateNewPassword}
              placeholder="**********"
            />
            <Button colorScheme="blue" type="submit" isLoading={isSubmittingp}>
              Confirmar
            </Button>
          </Flex>
        </Flex>
      </Fade>
    );
  }

  return (
    <Fade in={true} delay={0.2}>
      <Helmet>
        <title>Recovery | Workspace</title>
      </Helmet>
      <Flex
        height="100vh"
        justify="center"
        align="center"
        backgroundColor={{ base: 'high.pure', md: 'transparent' }}
      >
        <Flex
          flexDirection="column"
          as="form"
          onSubmit={handleSubmit(handleAccountRecovery)}
          minHeight={{ base: '100%', md: '520px' }}
          justify={{ base: 'space-around', md: 'inherit' }}
          width={570}
          borderWidth={{ base: '0px', md: '1px' }}
          borderColor="low.light"
          py="16px"
          px={{ base: '24px', md: '98px' }}
          boxShadow={{ base: 'none', md: 'sm' }}
          backgroundColor="high.pure"
        >
          <Heading color="primary.pure" fontSize="24px" marginBottom="10px">
            Não consegue acessar?
          </Heading>
          <Text fontSize="14px" marginBottom="26px">
            Informe seu e-mail que enviaremos instruções e um link para que você
            possa recuperar sua senha.
          </Text>
          <Input
            {...register('email', { required: 'E-mail obrigatório' })}
            label="E-mail"
            placeholder="mail@provider.com"
            error={errors.email}
          />

          <Flex justify="space-between" marginTop="134px">
            <Button
              disabled={isSubmitting}
              onClick={() => navigate('/')}
              variant="link"
              color="primary.pure"
              width={{ base: '144px', md: '177px' }}
            >
              Voltar
            </Button>

            <Button
              isLoading={isSubmitting}
              type="submit"
              width={{ base: '144px', md: '177px' }}
              colorScheme="blue"
              leftIcon={<IoMailOutline size={22} />}
            >
              Enviar link
            </Button>
          </Flex>

          <Flex
            // flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop="30px"
          >
            <Text fontSize="14px" textAlign="center" color="low.light">
              Problemas para acessar? Acesse nossa <br />
              <Link
                fontSize="14px"
                color="primary.pure"
                href="https://londrisoft.movidesk.com/kb/pt-br"
                target="_blank"
              >
                Central de Ajuda
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};
